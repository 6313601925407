import firebase from "firebase/app";
import "firebase/database";

// const firebaseConfig = {
//     apiKey: "AIzaSyBv9rWcuwbVURaepCxaaQNAK_rZiV7ILcE",
//     authDomain: "contactform-a3e76.firebaseapp.com",
//     projectId: "contactform-a3e76",
//     storageBucket: "contactform-a3e76.appspot.com",
//     messagingSenderId: "303120345040",
//     appId: "1:303120345040:web:c3d47278a559ed3f61fbd5"
// };

const firebaseConfig = {
  apiKey: "AIzaSyDomVsjefP_fDgSuDhNUaI_n0xpFKExU8g",
  authDomain: "beingdeveloper-2e390.firebaseapp.com",
  projectId: "beingdeveloper-2e390",
  storageBucket: "beingdeveloper-2e390.appspot.com",
  messagingSenderId: "427948754408",
  appId: "1:427948754408:web:dfb30de42a96f9386b905d",
  measurementId: "G-5JXE5JGQTN"
};

firebase.initializeApp(firebaseConfig);
var database = firebase.database();

export default database;
